<template>
    <div class="navbar">
        <div class="navbar-blur" v-if="isScrolled"></div>
        <div class="navbar-start">
            
            <a href="/" class="navbar-brand" id="dateltec">
                <img height="70" width="70" alt="DATELTEC" src="../assets/img/logo.png">
            </a>

            <a href="/">
                <span class="dateltec text-green-500 pl-4">DATELTEC</span>
            </a>
            <div class="dropdown hover:text-green-500">
                <label tabindex="0" class="btn btn-ghost lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                </label>
                
                <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 w-52">
                    <li>
                        <router-link class="hover:text-green-500" to="/" @click.native="removeFocus">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                            </svg>
                            Home
                        </router-link>
                    </li>
                    
                    <li>
                        <router-link class="hover:text-green-500" to="/servicios" @click.native="removeFocus">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                            Servicios
                        </router-link>
                    </li>

                    <li>
                        <router-link class="hover:text-green-500" to="/about" @click.native="removeFocus">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            About
                        </router-link>
                    </li>

                    <li>
                        <router-link class="hover:text-green-500" to="/contacto" @click.native="removeFocus">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z" />
                            </svg>
                            Contacto
                        </router-link>
                    </li>
                </ul>
            </div>          
        </div>
  
        <div class="navbar-center primary hidden lg:flex">
            <ul class="menu menu-horizontal px-1">
                <li>
                    <router-link class="hover:text-green-500" to="/" @click.native="removeFocus">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        Home
                    </router-link>
                </li>

                <li>
                    <router-link class="hover:text-green-500" to="/servicios" @click.native="removeFocus">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        Servicios
                    </router-link>
                </li>
      
                <li>
                    <router-link class="hover:text-green-500" to="/about" @click.native="removeFocus">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        About
                    </router-link>
                </li>

                <li>
                    <router-link class="hover:text-green-500" to="/contacto" @click.native="removeFocus">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z" />
                        </svg>
                        Contacto
                    </router-link>
                </li>
            </ul>
        </div>
  
        <div class="navbar-end">
            <a class="hover:text-green-500 contact-link" @click.prevent="toggleOptions">
                <span class="whatsapp-icon-container">
                    <svg class="whatsapp-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">
                        <path fill="#fff" d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24	c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"></path><path fill="#fff" d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"></path><path fill="#cfd8dc" d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3	l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"></path><path fill="#40c351" d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8l6-1.6l0.6,0.3	c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"></path><path fill="#fff" fill-rule="evenodd" d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4	s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6	s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4	c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8	C20.6,19.3,19.7,17,19.3,16z" clip-rule="evenodd"></path>
                    </svg>
                </span>
                Contáctanos al {{ displayNumber }}
            </a>
            <div v-if="showOptions" class="options-container">
                <a class="option hover:text-green-500" :href="`tel:${fullPhoneNumber}`">Llamar</a>
                <a class="option hover:text-green-500" :href="`https://wa.me/${fullPhoneNumber}`">WhatsApp</a>
            </div>
        </div>
    </div>
</template>


<script>
import { svg } from 'd3';

    export default {
    name: 'Navbar',
    data() {
        return {
            showOptions: false,
            countryCode: '+34',
            phoneNumber: '614210378',
            isScrolled: false,
        };
    },
    
    computed: {
        fullPhoneNumber() {
            return this.countryCode + this.phoneNumber;
        },
        displayNumber() {
            return this.phoneNumber;
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('click', this.handleClickOutside);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        },
        toggleOptions() {
            this.showOptions = !this.showOptions;
            this.removeFocus();
        },
        removeFocus() {
            document.activeElement.blur();
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target) && this.showOptions) {
                this.showOptions = false;
            }
        }
    },
    components: { svg }
}
</script>

<style>
    @import url('@/assets/styles/fonts.css');

    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        display: flex;
        align-items: center;
    }

    .navbar-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        backdrop-filter: blur(10px);
        z-index: -1;
    }

    .navbar-start {
        font-family: 'Titillium', serif;
        color: #52c84a;
        font-size: 15pt;
        z-index: 1000;
    }

    .whatsapp-icon-container {
        display: inline-flex;
        align-items: center;
        margin-right: 5px;
        position: relative;
        left: -10px;
        z-index: 1000;
    }

    @media (min-width: 361px) and (max-width: 490px) {
        .whatsapp-icon-container {
            left: 15px; 
        }
    }

    @media (min-width: 491px) and (max-width: 600px) {
        .whatsapp-icon-container {
            left: 5px; 
        }
    }

    @media (min-width: 601px) and (max-width: 720px) {
        .whatsapp-icon-container {
            left: 5px; 
        }
    }

    @media (min-width: 721px) and (max-width: 840px) {
        .whatsapp-icon-container {
            left: 5px; 
        }
    }

    @media (min-width: 841px) and (max-width: 960px) {
        .whatsapp-icon-container {
            left: 5px; 
        }
    }

    @media (min-width: 961px) and (max-width: 1080px) {
        .whatsapp-icon-container {
            left: 0px; 
        }
    }

    @media (min-width: 1081px) and (max-width: 1200px) {
        .whatsapp-icon-container {
            left: 0px; 
        }
    }

    .whatsapp-icon {
        height: 30px;
        width: 30px;
        fill: green;
        z-index: 1000;
    }

    .contact-link {
        display: flex;
        align-items: center;
        z-index: 1000;
    }

    .options-container {
        position: absolute;
        top: 60%;
        right: 0;
        background-color: transparent;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .option {
        color: black;
        padding-top: 10px;
        text-decoration: none;
        display: block;
    }

    .option:hover {
        background: transparent;
    }

</style>